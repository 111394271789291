import React from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/seo"

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import SuccessSection from "../../components/SuccessSection"

const Success = () => {
   return (
      <Layout>
         <SEO title="Página de Sucesso"/>
         <Header title=""/>
         <SuccessSection 
            title="Obrigado!"
            message="Sua mensagem foi enviada com sucesso!"
            label="Continuar" 
            target="/" />
         <Footer />
      </Layout>
   )
}

export default Success;