import styled from "styled-components"
import { Link } from "gatsby"

import media from "../../styles/breakPoints"

const Container = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: 0;
   padding: 10rem 0;
`

const Title = styled.h1`
   font-family: ${props => props.theme.font.primary};
   font-weight: 600;
   font-size: ${props => props.theme.size.medium};
   line-height: 3rem;
   color: ${props => props.theme.color.fourthDark2};
   margin-bottom: 1rem;
`

const Message = styled.p`
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.small};
   color: ${props => props.theme.color.grayLight};
   margin-bottom: 3rem;
`

const Button = styled(Link)`
   /* button */
   width: 200px;
   height: 30px;
   text-decoration: none;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: ${props => props.theme.color.fourthLight1};
   border: none;
   padding: 0 10px;
   cursor: pointer;
   /* label */
   font-family: ${props => props.theme.font.secondy};
   font-weight: 400;
   font-size: ${props => props.theme.size.small};
   line-height: 30px;
   color: ${props => props.theme.color.grayZero};

   ${media.greaterThan("lg")`
      transition: transform 300ms linear;
      &:hover {
         transform: scale(1.1);
         transition: transform 300ms linear;
      }
   `}
   
`

export { Container, Title, Message, Button }