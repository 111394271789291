import React from "react";

import MainSection from "../../objects/MainSection"
import { Container, Title, Message, Button } from "./styles"

const SuccessSection = ({ label, target, title, message }) => {
   return (
      <MainSection>
         <Container>
            <Title>{title}</Title>
            <Message>{message}</Message>
            <Button to={target}>{label}</Button>
         </Container>
      </MainSection>
   )
}

export default SuccessSection